


/*===========================
    19.LEADING css 
===========================*/



.leading-area{
	padding-bottom: 120px;
	position: relative;
	z-index: 10;
	& .leading-line{
		position: absolute;
		top: -80px;
		left: 195px;
		z-index: -1;
		animation: bubbleMover2 linear infinite 5s;
		@media #{$laptop} {
			left: 0;
		}
	}
	& .leading-dot{
		position: absolute;
		right: 55px;
		bottom: -30px;
		z-index: -1;
		animation: pusher linear infinite 10s;
		@media #{$laptop} {
			right: 0px;
			bottom: -50px;
		}
		@media #{$lg} {
			right: 0px;
			bottom: -60px;
		}
	}
	& .leading-thumb{
		position: relative;
		&::before{
			position: absolute;
			content: '';
			left: -50px;
			top: 50%;
			transform: translateY(-50%);
			width: 50px;
			height: 90%;
			background: #fbf0ee;
		}
		& img{
			width: 100%;
		}
	}
	& .leading-content{
		margin-top: -4px;
		@media #{$lg} {
			margin-left: 0;
			margin-top: 0;
		}
		@media #{$md} {
			margin-left: 0;
			margin-top: 30px;
		}
		@media #{$xs} {
			margin-left: 0;
			margin-top: 30px;
		}
		& .title{
			font-size: 44px;
			padding-bottom: 20px;
			@media #{$xs} {
				font-size: 32px;
			}
			@media #{$sm} {
				font-size: 44px;
			}
		}
		& p{
			padding-bottom: 16px;
			padding-top: 20px;
		}
		& .list-item{
			padding-top: 30px;
			& .item{
				background: #fbf0ee;
				padding: 30px;
				& span{
					font-weight: 700;
					color: #3f3836;
					letter-spacing: -.5px;
					@media #{$lg} {
						font-size: 15px;
					}
					& i{
						height: 40px;
						width: 40px;
						text-align: center;
						line-height: 40px;
						background: $theme-color;
						text-align: center;
						line-height: 40px;
						color: $white;
						font-size: 14px;
						margin-right: 18px;
						@include transition(0.3s);
					}

				}
				&.item-2{
					background-color: $white;
				}
				&:hover{
					& span{
						& i{
							transform: scale(1.1);
						}
					}
				}
			}
		}

	}
}