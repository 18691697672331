


/*===========================
    32.CONTACT css 
===========================*/





.contact-info-area{
	padding-top: 120px;
	& .contact-info{
		border-bottom: 1px solid #f7eae8;
		padding-bottom: 120px;
	}
	& .contact-info-content{
		margin-top: -9px;
		& .title{
			font-size: 44px;
			color: #3f3836;
			padding-bottom: 27px;
		}
		& ul{
			& li{
				font-weight: 600;
				font-size: 16px;
				line-height: 44px;
				position: relative;
				padding-left: 33px;
				& img{
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
	& .contact-info-thumb{
		@media #{$md} {
			margin: 30px 0;
		}
		@media #{$xs} {
			margin: 30px 0;
		}
		& img{
			width: 100%;
		}
	}
	& .contact-info-text{
		padding-left: 30px;
		margin-top: -12px;
		@media #{$lg} {
			padding-left: 0;
		}
		@media #{$md} {
			padding-left: 0;
		}
		@media #{$xs} {
			padding-left: 0;
		}
		& p{
			font-weight: 500;
			&.text{
				padding-top: 32px;
				padding-bottom: 35px;
			}
		}

	}
}