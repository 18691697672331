/*===========================
    16.FOOTER css 
===========================*/





.footer-area {
    background: #2A1633;
    padding-top: 88px;
    position: relative;
    z-index: 10;

    & .footer-pattern {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    & .footer-logo {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    & .footer-about {
        & p {
            color: #cac6c4;
            font-size: 15px;
            line-height: 30px;
            padding-right: 35px;
            padding-top: 40px;
            padding-bottom: 33px;

            @media #{$lg} {
                padding-right: 0;
            }

            @media #{$md} {
                padding-right: 0;
            }

            @media #{$xs} {
                padding-right: 0;
            }
        }

        & ul {
            & li {
                & a {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 34px;
                    color: $theme-color;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }

    & .footer-list {
        @media #{$md} {
            padding-left: 0;
        }

        @media #{$xs} {
            padding-left: 0;
        }

        & .title {
            color: $white;
            font-size: 18px;
            letter-spacing: -1px;
            padding-top: 7px;
            padding-bottom: 31px;
        }

        & ul {
            & li {
                & a {
                    font-size: 15px;
                    font-weight: 600;
                    color: #cbc7c5;
                    line-height: 36px;
                    @include transition(0.3s);

                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }

    & .footer-address {
        & .title {
            color: $white;
            font-size: 18px;
            letter-spacing: -1px;
            padding-top: 7px;
            padding-bottom: 37px;
        }

        & p {
            font-size: 15px;
            color: #cbc7c5;
            font-weight: 600;
            line-height: 26px;
            padding-bottom: 20px;
        }

        & iframe {
            width: 100%;
            height: 95px;
        }

        & span {
            font-size: 14px;
            font-weight: 600;
            color: $white;
            padding-top: 5px;

            & i {
                color: $theme-color;
                padding-right: 6px;
            }
        }

    }
}



.footer-copyright-area {
    margin-top: 95px;

    & .footer-copyright {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 34px 0;

        @media #{$xs} {
            text-align: center;
        }

        & p {
            font-size: 15px;
            font-weight: 600;
            color: #cac6c4;

            @media #{$xs} {
                padding-bottom: 15px;
            }
        }

        & ul {
            & li {
                display: inline-block;

                & a {
                    height: 46px;
                    width: 46px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 46px;
                    border-radius: 50%;
                    text-align: center;
                    background: #342d2c;
                    color: $white;
                    margin-left: 4px;
                    @include transition(0.3s);

                    @media #{$xs} {
                        margin: 0 3px;
                    }

                    &:hover {
                        background: $theme-color;
                    }
                }
            }
        }
    }
}











/*===== Go Top =====*/


.go-top-area {
    position: relative;
    z-index: 999;

    & .go-top {
        position: fixed;
        cursor: pointer;
        top: 0;
        right: 24px;
        color: #ffffff;
        background-color: $theme-color;
        z-index: 9999;
        width: 40px;
        text-align: center;
        height: 42px;
        line-height: 42px;
        opacity: 0;
        visibility: hidden;
        @include transition(0.9s);
        border-radius: 10px;

        & i {
            position: absolute;
            top: 50%;
            left: -4px;
            right: 0;
            margin: 0 auto;
            font-size: 15px;
            @include transform(translateY(-50%));
            @include transition(0.5s);

            &:last-child {
                opacity: 0;
                visibility: hidden;
                top: 60%;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: $theme-color;
            opacity: 0;
            visibility: hidden;
            @include transition(0.5s);
            border-radius: 10px;
        }

        &:focus,
        &:hover {
            color: $white;

            &::before {
                opacity: 1;
                visibility: visible;
            }

            & i {
                &:first-child {
                    opacity: 0;
                    top: 0;
                    visibility: hidden;
                }

                &:last-child {
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
                }
            }
        }

        &.active {
            top: 98%;
            @include transform(translateY(-98%));
            opacity: 1;
            visibility: visible;
            border-radius: 10px;
            right: 20px;

            @media #{$lg} {
                top: 93%;
            }

            @media #{$md} {
                top: 93%;
            }
        }
    }
}


.go-top-wrap {
    position: relative;

    & .go-top-btn {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: $white;
        top: 3px;
        z-index: 1;
        background: $theme-color;
        border-radius: 10px;

        & i {
            font-size: 20px;
            font-weight: 700;
            padding-left: 4px;
            color: $white;
        }

        &::after {
            z-index: -1;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            height: 40px;
            @include animation(ripple 1.6s ease-out infinite);
            opacity: 0;
            background: $theme-color;
            border-radius: 10px;
        }

        &:hover {
            background-color: $black;
            color: $white;
        }
    }
}

@keyframes ripple {

    0%,
    35% {
        @include transform(scale(0));
        opacity: 1;
    }

    50% {
        @include transform(scale(1.5));
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        @include transform(scale(2));
    }
}

@-webkit-keyframes ripple {

    0%,
    35% {
        @include transform(scale(0));
        opacity: 1;
    }

    50% {
        @include transform(scale(1.5));
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        @include transform(scale(2));
    }
}




.sing-area {
    z-index: 11;
    position: relative;

    & .footer-sing-up {
        margin-top: 0;
        margin-bottom: -60px;
    }
}







@-webkit-keyframes footer-shape {
    0% {
        right: 100px;
    }

    50% {
        right: 0;
    }

    100% {
        right: 100px;
    }
}

@keyframes footer-shape {
    0% {
        right: 100px;
    }

    50% {
        right: 0;
    }

    100% {
        right: 100px;
    }
}











.map-area {
    margin-bottom: -7px;

    & iframe {
        width: 100%;
        height: 560px;
    }
}