$theme-colors: (
  'primary': #ff8f13,
  'secondary': #fff,
  'danger': #ff4136,
);
@import '~bootstrap/scss/bootstrap';
@import './assets/css/font-awesome.min.css';
@import './assets/css/flaticon.css';
@import './assets/css/popicon.css';
@import './assets/css/nice-select.css';
@import './assets/css/animate.min.css';
@import './assets/css/magnific-popup.css';
@import './assets/css/default.css';
@import './assets/scss/style';

.tf-v1-widget iframe {
  border-radius: 0px !important;
}

.career-form {
  height: 90vh;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 25px;
  right: 15px;
  background-color: var(--primary);
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}