	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/05/2020 09:58
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-message:before { content: "\f100"; }
.flaticon-phone-call:before { content: "\f101"; }
.flaticon-placeholder:before { content: "\f102"; }
.flaticon-right-arrow:before { content: "\f103"; }
.flaticon-arrow:before { content: "\f104"; }
.flaticon-computer:before { content: "\f105"; }
.flaticon-workspace:before { content: "\f106"; }
.flaticon-meeting:before { content: "\f107"; }
.flaticon-plus:before { content: "\f108"; }
.flaticon-minus:before { content: "\f109"; }
.flaticon-wifi:before { content: "\f10a"; }
.flaticon-tray:before { content: "\f10b"; }
.flaticon-parking:before { content: "\f10c"; }
.flaticon-room:before { content: "\f10d"; }
.flaticon-cctv:before { content: "\f10e"; }
.flaticon-menu:before { content: "\f10f"; }
.flaticon-calendar:before { content: "\f110"; }
.flaticon-alarm-clock:before { content: "\f111"; }
.flaticon-map:before { content: "\f112"; }