// Font Family
$montserrat: 'Altone Trial',
sans-serif;


// Colors ---------------
$white: #fff;
$black: #222;
$grey:#f7f7fd;
$theme-color: #ff8f13;
$text-color: #817a78;
$hr-border-color:#eceff8;


// Heading Color
$heading-color: #3f3836;

// Soft color
$black-soft:#7e7e7e;


// Responsive Variables
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';