@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?uy4xyk');
  src: url('../fonts/icomoon.eot?uy4xyk#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?uy4xyk') format('truetype'),
    url('../fonts/icomoon.woff?uy4xyk') format('woff'),
    url('../fonts/icomoon.svg?uy4xyk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-car-parking:before {
  content: "\e903";
}

.icon-desk:before {
  content: "\e904";
}

.icon-mail-handling:before {
  content: "\e905";
}

.icon-wifi-small:before {
  content: "\e906";
}

.icon-kitchen:before {
  content: "\e907";
}

.icon-conference-room:before {
  content: "\e908";
}

.icon-chair:before {
  content: "\e909";
}

.icon-wifi:before {
  content: "\e90a";
}

.icon-email:before {
  content: "\e900";
}

.icon-map:before {
  content: "\e901";
}

.icon-phone:before {
  content: "\e902";
}