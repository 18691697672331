


/*===========================
    18.FIND SPACE css 
===========================*/



.find-space-area{
	padding-top: 111px;
	 padding-bottom: 120px;
	 position: relative;
	 z-index: 10;
	& .find-pattern{
	 	position: absolute;
	 	top: 0;
	 	right: 0;
	 	z-index: -1;
	}
	& .find-space-title{
		padding-bottom: 30px;
		& .title{
			font-size: 44px;
		}
	}
	& .find-space-item{
		margin-right: -5px;
		min-width: 25%;
		@media #{$md} {
			margin-right: 0;
		}
		@media #{$xs} {
			margin-right: 0;
			min-width: 100%;
		}
		& .find-space-thumb{
			min-width: 285px;
			@media #{$lg} {
				min-width: 225px;
			}
			@media #{$md} {
				min-width: 50%;
			}
			@media #{$xs} {
				min-width: 50%;
			}
			& img{
				width: 100%;
			}
		}
		& .find-space-content{
			border: 1px solid #f7eae8;
			padding: 34px 55px 31px 50px;
			background: $white;
			transform: scale(0);
			height: 316px;
			@media #{$lg} {
				padding: 34px 20px 31px;
				height: 249px;
			}
			@media #{$md} {
				transform: scale(1);
				height: auto;
			}
			@media #{$xs} {
				padding: 34px 20px 31px;
				transform: scale(1);
				height: auto;
				margin-right: 0 !important;
			}
			@media #{$sm} {
				padding: 34px 20px 31px;
			}
			& .title{
				font-size: 22px;
				line-height: 30px;
			}
			& p{
				font-weight: 500;
				padding-top: 22px;
				padding-bottom: 8px;
				@media #{$lg} {
					padding-top: 15px;
				}
			}
			& a{
				font-size: 20px;
				color: $theme-color;
			}
		}
		&.active{
			min-width: 50%;
			& .find-space-content{
				transform: scale(1);
				margin-right: 15px;
			}
		}
	}
	& .find-space-preview{
		margin-left: -15px;
		@media #{$md} {
			margin-left: 0;
		}
		@media #{$xs} {
			margin-left: 0;
		}
		& .thumb{
			@media #{$md} {
				width: 50%;
				margin: 0;
			}
			@media #{$xs} {
				margin: 0;
			}
			& img{
				width: 100%;
			}
		}
	}



}
