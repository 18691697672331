


/*===========================
    23.UPPCOMING EVENTS css 
===========================*/




.upcoming-events-area{
	background: #fbf0ee;
	padding-top: 114px;
	padding-bottom: 120px;
	position: relative;
	z-index: 10;
	& .events-pattern{
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}
	& .upcoming-events-title{
		padding-bottom: 38px;
		& .title{
			font-size: 44px;
		}
	}
	.upcoming-events-item{
		& .item{
			background: $white;
			padding: 30px;
			border: 1px solid #f7eae8;
			@include transition(0.3s);
			&:hover{
				box-shadow: 0px 20px 20px 0px rgba(63, 56, 54, 0.04);
			}
		}
	}
	& .upcoming-events-content{
		& .thumb{
			min-width: 130px;
			position: relative;
			@media #{$xs} {
				min-width: auto;
				max-width: 130px;
			}
			& .date{
				position: absolute;
				right: -26px;
				bottom: 0;
				height: 52px;
				width: 52px;
				text-align: center;
				background: $theme-color;
				& span{
					color: $white;
					font-size: 13px;
					font-weight: 700;
					line-height: 15px;
					padding-top: 10px;
				}
			}
		}
		& .content{
			@media #{$xs} {
				margin-left: 0;
				padding: 30px 0;
			}
			& ul{
				& li{
					display: inline-block;
					font-size: 14px;
					color: $text-color;
					font-weight: 500;
					margin-right: 22px;
					& i{
						color: $theme-color;
						padding-right: 6px;
					}
					&:last-child{
						margin-right: 0;
					}
				}
			}
			& .title{
				font-size: 24px;
				padding-top: 7px;
			}
			& p{
				font-weight: 500;
				padding-top: 14px;
				@media #{$lg} {
					font-size: 15px;
				}
			}
		}
	}
	& .events-btn{
		@media #{$md} {
			margin-top: 30px;
		}
	}
	&.events-page{
		background-color: transparent;
		padding-top: 100px;
	}
}










.event-details-area{
	padding-top: 111px;
	& .event-details-content{
		@media #{$md} {
			margin-bottom: 30px;
		}
		@media #{$xs} {
			margin-bottom: 30px;
		}
		& .title{
			font-size: 44px;
			padding-right: 30px;
			padding-bottom: 34px;
			@media #{$xs} {
				font-size: 30px;
			}
		}
		& p{
			font-weight: 500;
			padding-right: 10px;
			&.text{
				padding-top: 31px;
			}
		}
	}
	& .event-details-thumb{
		margin-top: 8px;
		& img{
			width: 100%;
		}
	}
}




.event-info-area{
	padding-bottom: 120px;
	& .event-info-item{
		padding: 55px 60px 60px;
		background: $heading-color;
		@media #{$lg} {
			padding: 55px 30px 60px;
		}
		@media #{$xs} {
			padding: 55px 30px 60px;
		}
		@media #{$sm} {
			padding: 55px 60px 60px;
		}
		& .title{
			color: $white;
			font-size: 24px;
		}
		& p{
			font-weight: 500;
			color: #cbc7c5;
			line-height: 30px;
			padding-right: 7px;
			padding-top: 32px;
			padding-bottom: 33px;
			@media #{$lg} {
				padding-right: 0;
				font-size: 15px;
			}
		}
		& .info{
			& ul{
				& li{
					font-weight: 700;
					color: $white;
					line-height: 40px;
					& i{
						color: $theme-color;
						padding-right: 10px;
						font-size: 20px;
					}
				}
			}
		}
		& .social{
			padding-top: 26px;
			& ul{
				& li{
					display: inline-block;
					& a{
						background: #342d2c;
						height: 46px;
						width: 46px;
						text-align: center;
						line-height: 46px;
						border-radius: 50%;
						color: $white;
						margin-right: 4px;
						@include transition(0.3s);
						&:hover{
							background: $theme-color;
						}
					}
				}
			}
		}
		&.item-2{
			background: $theme-color;
			& p{
				color: $white;
				padding-right: 0;
			}
			& .info{
				& ul{
					& li{
						line-height: 26px;
						padding: 15px 0 15px 33px;
						position: relative;

						@media #{$xs} {
							font-size: 15px;
						}
						& i{
							color: $white;
							font-size: 20px;
							position: absolute;
							left: 0;
							top: 15px;
						}
					}
				}
			}
		}
	}
	& .event-info-map{
		& iframe{
			width: 100%;
			height: 490px;
		}
	}

}