/*===========================
    11.Infographics css 
===========================*/

.infographics {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;

  & .title {
    font-size: 44px;
    color: #3f3836;
    letter-spacing: 0.1ch;
    position: relative;
    font-weight: 700;
    margin: 0px;
    letter-spacing: -1.5px;

    @media #{$xs} {
      margin-bottom: 30px;
      font-size: 32px;
    }
  }

}