


/*===========================
    24.UPPCOMING EVENTS css 
===========================*/



.join-thousands-area{
	padding-top: 111px;
	padding-bottom: 120px;
	position: relative;
	z-index: 5;
	& .thousands-line{
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
	}
	& .thousands-dot{
		position: absolute;
		bottom: 105px;
		right: 320px;
		z-index: -1;
		animation: bubbleMover linear infinite 8s;
		@media #{$laptop} {
			right: 0;
		}
	}
	& .join-thousands-thumb{
		margin-left: -100px;
		@media #{$lg} {
			margin-left: 0;
		}
		@media #{$md} {
			margin-left: 0;
		}
		@media #{$xs} {
			margin-left: 0;
		}
		& > img{
			
		}
	}
	& .join-thousands-content{
		@media #{$lg} {
			margin-left: 0;
		}
		@media #{$md} {
			margin-left: 0;
			margin-top: 30px;
		}
		@media #{$xs} {
			margin-left: 0;
			margin-top: 30px;
		}
		& .title{
			font-size: 44px;
			padding-bottom: 48px;
			@media #{$lg} {
				font-size: 38px;
			}
			@media #{$xs} {
				font-size: 32px;
			}
			@media #{$sm} {
				font-size: 44px;
			}

		}
		& .item{
			& span{
				font-size: 20px;
				font-weight: 700;
				color: $heading-color;
				@media #{$xs} {
					font-size: 18px;
				}
				& span{
					background: #fbf0ee;
					padding: 0 20px;
					line-height: 36px;
					font-size: 14px;
					font-weight: 600;
					margin-right: 15px;
					& i{
						padding-left: 5px;
					}
				}
			}
			& p{
				font-weight: 500;
				letter-spacing: -.3px;
				padding-top: 20px;
			}
		}
	}


}

