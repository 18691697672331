/*===========================
    1. COMMON css 
===========================*/


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


@font-face {
    font-family: 'Altone Trial';
    src: url('../fonts/altone-trial.bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Altone Trial';
    src: url('../fonts/altone-trial.regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

body {
    font-family: $montserrat;
    font-weight: normal;
    font-style: normal;
    color: $text-color;
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
    @include box-sizing (border-box);
}

img {
    max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

i,
span,
a {
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $montserrat;
    font-weight: 700;
    color: $heading-color;
    margin: 0px;
    letter-spacing: -1.5px;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    font-size: 16px;
    font-weight: 600;
    line-height: 34px;
    color: $text-color;
    margin: 0px;
    letter-spacing: -.3px;
}

.bg_cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.slick-slide {
    outline: 0;
}

// others common css here



/*===== All Button Style =====*/

.main-btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    @include user-select(none);
    //border-left: 2px solid $white;
    padding: 0 40px;
    font-size: 16px;
    line-height: 68px;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    z-index: 5;
    @include transition(0.4s);
    background-color: $theme-color;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        left: auto;
        top: 0;
        right: 0;
        height: 100%;
        width: 0px;
        background: $white;
        z-index: -1;
        @include transition(0.4s);
    }

    &:hover {
        color: $theme-color;

        &::before {
            width: 100%;
            left: 0;
            right: auto;
        }
    }

    &.main-btn-2 {

        //border-left-color: #3f3836;
        &::before {
            background: #3f3836;
        }

        &:hover {
            color: $white;

        }
    }

    &.main-btn-small {
        line-height: 36px;
        padding: 0 20px;
        font-size: 14px;
        background: $white;
        color: $theme-color;
    }

}






/*Loader Css*/
.preloader {
    background-color: #fff;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.lds-ellipsis {
    margin: 0 auto;
    position: relative;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 64px;
    text-align: center;
    z-index: 9999;
}

.lds-ellipsis span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $theme-color;
    -webkit-animation: ball-pulse-sync .6s 0s infinite ease-in-out;
    animation: ball-pulse-sync .6s 0s infinite ease-in-out;
}

.lds-ellipsis span:nth-child(1) {
    -webkit-animation: ball-pulse-sync .6s -.14s infinite ease-in-out;
    animation: ball-pulse-sync .6s -.14s infinite ease-in-out
}

.lds-ellipsis span:nth-child(2) {
    -webkit-animation: ball-pulse-sync .6s -70ms infinite ease-in-out;
    animation: ball-pulse-sync .6s -70ms infinite ease-in-out
}

@-webkit-keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

















@keyframes bubbleMover {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    50% {
        -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
        transform: translateY(50px) translateX(100px) rotate(45deg);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
    }

    80% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }

    100% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}

@keyframes bubbleMover2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(10px) translateX(20px) rotate(5deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    50% {
        transform: translateY(20px) translateX(40px) rotate(20deg);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(40px) rotate(5deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }

    100% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}



@keyframes pusher {
    33% {

        transform: scale(.7);
    }

    66% {
        transform: scale(1);
    }

    100% {
        transform: scale(.7);
    }
}